import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import {signInWithEmailAndPassword, getAuth, signOut} from "firebase/auth";
import { doc, setDoc, collection, getCountFromServer, getDoc } from "firebase/firestore"; 

const firebaseConfig = {
  apiKey: "AIzaSyClJTTe-YYbxgGaew3g908xPLHfou6L6k0",
  authDomain: "speakeasy-272b6.firebaseapp.com",
  projectId: "speakeasy-272b6",
  storageBucket: "speakeasy-272b6.appspot.com",
  messagingSenderId: "728609598509",
  appId: "1:728609598509:web:5461e9c9e01adcf6115ca4",
  measurementId: "G-8Q964XLKDY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);

const getCount = async (uid, deck) => {
  const ref = collection(db, uid, deck, "attempts");
  const snapshot = await getCountFromServer(ref);
  // console.log(snapshot.data().count);
  return parseInt(snapshot.data().count);
}

const addFeedback = async (uid, deck, feedback) => {
  const ref = collection(db, uid, deck, "attempts");
  const count = await getCount(uid, deck);
  console.log(count);
  await setDoc(doc(ref, "Attempt ".concat(count+1)), feedback);
}

const addUserDetails = async (uid, name, license) => {
  const ref = doc(db, uid, "profile");
  await setDoc(ref, {"name": name, "vowelsCorrect": 0, "vowelsAttempted": 0, "consonantsCorrect": 0, "consonantsAttempted": 0});
  const ref2 = doc(db, "licenses", license);
  await setDoc(ref2, {"used": "true"});
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const getDocContent = async (uid, deck, num) => {
  const ref = collection(db, uid, deck, "attempts");
  // const count = await getCount(uid, deck);
  // console.log(count);
  const docRef = doc(ref, "Attempt ".concat(num));
  
  // const docRef = doc(db, "cities", "SF");
	const docSnap = await getDoc(docRef);
  return docSnap.data();
}

const checkLicenseUsed = async (license) => {
  const ref = collection(db, "licenses");
  const snap = await getDoc(doc(ref, license));
  return snap.exists();
}

const getUsername = async (uid) => {
  const ref = collection(db, uid);
  // const count = await getCount(uid, deck);
  // console.log(count);
  const docRef = doc(ref, "profile");
  
  // const docRef = doc(db, "cities", "SF");
	const docSnap = await getDoc(docRef);
  const data = docSnap.data();

  const decks = ["front-desk", "restaurants", "housekeeping"];
  const sentenceLists = {
    "front-desk": ['This is a test', 'Enjoy your stay', 'Safe travels'],
    "restaurants": ['Would you like some water', 'Here is the main course', 'May I interest you in dessert'],
    "housekeeping": ['When should I return', 'Here are clean sheets']
  };

  // // iterate through decks  
  // // if (props.feedback.comments.length > 0) {
	// 	const VOWELS = "əaiɛeɪoɐiːʌuæaɪɔɑːɚeɪuyɑ̃oʊᵻeaʊoːɔ̃ɜɑ";

	// // 	/* OVERALL */
	// // 	const overall = props.feedback.comments.filter(phoneme => phoneme.feedback.length == 0);
	// // 	const percentage = Math.round(overall.length / props.feedback.comments.length * 100);
		
	// // 	/* VOWELS */
	// // 	const vowels = overall.filter(phoneme => phoneme.feedback.length == 0 && VOWELS.includes(phoneme.phoneme));
	// // 	const all_vowels = props.feedback.comments.filter(phoneme => VOWELS.includes(phoneme.phoneme));
	// // 	const v_percentage = Math.round(vowels.length / all_vowels.length * 100);

	// // 	/* CONSONANTS */
	// // 	const consonants = overall.filter(phoneme => phoneme.feedback.length == 0 && !VOWELS.includes(phoneme.phoneme));
	// // 	const all_consonants = props.feedback.comments.filter(phoneme => !VOWELS.includes(phoneme.phoneme));
	// // 	const c_percentage = Math.round(consonants.length / all_consonants.length * 100);

  // let vowels = 0;
  // let consonants = 0;
  // let allVowels = 0;
  // let allConsonants = 0;

  let decksCompleted = 0;

  for (let i = 0; i < decks.length; i++) { // for each deck
    const deck = decks[i];
    let passedAll = true;
    for (let j = 1; j <= sentenceLists[deck].length; j++) { // for each exercise
      const docRef = doc(db, uid, sentenceLists[deck][j], "attempts", "bestAttempt");
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        passedAll = false;
        break;
      }
      const d = docSnap.data();
      const o = d.comments.filter(phoneme => phoneme.feedback.length == 0);
		  const p = Math.round(o.length / d.comments.length * 100);
      if (p < 95) {
        passedAll = false;
        break;
      }
    }
    if (passedAll) {
      decksCompleted++;
    }
  }

  console.log( data["vowelsAttempted"],  data["consonantsAttempted"])

  return {
    "name": data["name"],
    "decksCompleted":decksCompleted,
    "v_percentage": data["vowelsAttempted"] == 0? 0 : data["vowelsCorrect"] / parseFloat(data["vowelsAttempted"]) * 100,
    "c_percentage": data["consonantsAttempted"] == 0? 0 : data["consonantsCorrect"] / parseFloat(data["consonantsAttempted"] )* 100,
  };
}

const getBestAttempt = async (uid, sentence) => {
  const docRef = doc(db, uid, sentence, "attempts", "bestAttempt");
  const docSnap = await getDoc(docRef);
  return docSnap;
}

const setBestAttempt = async (uid, sentence, data, vc, va, cc, ca) => {
  console.log(vc, va, cc, ca);
  const docRef = doc(db, uid, sentence, "attempts", "bestAttempt");
  await setDoc(docRef, data);
  const docRef2 = doc(db, uid, "profile");
  let data2 = await getDoc(docRef2);
  data2 = data2.data();
  console.log(data2);
  console.log(data2["vowelsCorrect"], data2["vowelsAttempted"], data2["consonantsCorrect"], data2["consonantsAttempted"]);
  console.log("set Best attempt with values:");
  console.log(vc+data2["vowelsCorrect"], va+data2["vowelsAttempted"], cc+data2["consonantsCorrect"], ca+data2["consonantsAttempted"]);
  await setDoc(docRef2, {"vowelsCorrect": vc+data2["vowelsCorrect"], "vowelsAttempted": va+data2["vowelsAttempted"], "consonantsCorrect": cc+data2["consonantsCorrect"], "consonantsAttempted": ca+data2["consonantsAttempted"]}, {merge: true});
}

const getSentenceData = async (uid, deck) => {
  // const decks = ["front-desk", "restaurants", "housekeeping"];
  const sentenceList = {
    "front-desk": ['This is a test', 'Enjoy your stay', 'Safe travels'],
    "restaurants": ['Would you like some water', 'Here is the main course', 'May I interest you in dessert'],
    "housekeeping": ['When should I return', 'Here are clean sheets']
  }[deck];

  const ret = sentenceList.map((sentence) => ({ [sentence]: [0, 0, 0] }));
  const VOWELS = "əaiɛeɪoɐiːʌuæaɪɔɑːɚeɪuyɑ̃oʊᵻeaʊoːɔ̃ɜɑ";


  for (let i = 0; i < sentenceList.length; i++) {
    const docRef = doc(db, uid, sentenceList[i], "attempts", "bestAttempt");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("IN HERE");
      const d = docSnap.data();
      const overallBest = d.comments.filter(phoneme => phoneme.feedback.length == 0);
			const percentageBest = Math.round(overallBest.length / d.comments.length * 100);
			
			/* VOWELS */
			const vowelsBest = overallBest.filter(phoneme => phoneme.feedback.length == 0 && VOWELS.includes(phoneme.phoneme));
			const all_vowels_best = d.comments.filter(phoneme => VOWELS.includes(phoneme.phoneme));
			const v_percentage = Math.round(vowelsBest.length / all_vowels_best.length * 100);

			/* CONSONANTS */
			const consonantsBest = overallBest.filter(phoneme => phoneme.feedback.length == 0 && !VOWELS.includes(phoneme.phoneme));
			const all_consonants_best = d.comments.filter(phoneme => !VOWELS.includes(phoneme.phoneme));
			const c_percentage = Math.round(consonantsBest.length / all_consonants_best.length * 100);
      ret[i][sentenceList[i]] = [percentageBest, v_percentage, c_percentage];
    }
  }
  return ret;
}


export {
  auth,
  logInWithEmailAndPassword,
  logout,
  getCount,
  addFeedback,
  getDocContent,
  getUsername,
  addUserDetails,
  checkLicenseUsed,
  getBestAttempt,
  setBestAttempt,
  getSentenceData
};