import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

function SideBar(props) {
	// const options = [
	// 	'This is a test',
	// 	'Lend me your ears',
	// 	// 'interesting', 'communication', 'dangerous'
	// ];
	const defaultOption = props.options[0];
	const defaultAccent = 'English (US)';
	//  onChange={this._onSelect} value={defaultOption}
	// languages = ["en-US"];

	return (
		<div className="side-bar" style={{minHeight: '90vh'}}>
			<h3>Select an accent</h3>
			<Dropdown options={props.accents} value={defaultAccent} onChange={option => props.setAccent(option.label)} />
			<br></br>
			<h3>Choose a phrase</h3>
			<Dropdown options={props.options} value={defaultOption} onChange={option => props.setSentence(option.label)} />
			{/* <Dropdown options={languages} value={language} onChange={language => props.setLanguage(language.label)} /> */}
			<form onSubmit={props.addSentence}>
				<input
					value={props.newSentence}
					onChange={event => {
						props.setNewSentence(event.target.value);
					}}
					placeholder="Type a new sentence..."
				/>
			</form>
		</div>
	);
}

export default SideBar;
