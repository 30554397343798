import { useRef, useMemo, createRef, useEffect, useState } from 'react';
import LineTo, { SteppedLineTo } from 'react-lineto';
import Plot from 'react-plotly.js';
import { Howl, Howler } from 'howler';

function FormantPlot(props) {
	const ROUNDEDNESS_THRESHOLD = 2600; // => subject to change!

	const { f1, f2, f3 } = props;
	const config = { static: true, displayModeBar: false };
	const style = { width: '100%', height: '100%' };
	const layout = {
		width: 230,
		height: 150,
		margin: { t: 0, r: 0, l: 0, b: 0 },
		xaxis: {
			tickmode: 'linear', //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
			tick0: 0.5,
			dtick: 100,
			range: [200, 2600],
			// autorange: "reversed",

			// tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
			// tickvals: [300, 400, 500, 600, 700, 800, 900],
			// ticktext: ['high', '', '', '', '', '', 'low'],
			title: '<- back          front ->',
		},
		yaxis: {
			// tickmode: "array",
			range: [1200, 200],
			// tickvals: [500, 1000, 1500, 2000, 2500],
			// ticktext: ['back', '', '', '', 'front'],
			title: '   <- low      high ->',
		},
	};
	const userFeedback = `Yours: F1 = ${Math.round(f1.user)}, F2 = ${Math.round(f2.user)}, F3 =  ${Math.round(f3.user)}`;
	const gtFeedback = `Goal: F1 = ${Math.round(f1.gt)}, F2 = ${Math.round(f2.gt)}, F3 =  ${Math.round(f3.gt)}`;
	let symbols = ['diamond-wide', 'diamond-wide'];
	if (f3.user < ROUNDEDNESS_THRESHOLD) {
		symbols[0] = 'circle';
	}
	if (f3.gt < ROUNDEDNESS_THRESHOLD) {
		symbols[1] = 'circle';
	}
	const data = {
		x: [f2.user, f2.gt],
		y: [f1.user, f1.gt],
		text: [userFeedback, gtFeedback],
		type: 'scatter',
		mode: 'markers',
		hoverinfo: 'text',
		marker: { size: 20, color: ['#ee3333', '#339933'], line: { width: 2, color: 'DarkSlateGrey' }, symbol: symbols },
		// marker: { size: 20, color: ['#ee3333', '#339933'], symbol: ['x', 'star'] },
	};
	return <Plot data={[data]} config={config} style={style} layout={layout} />;
}

function Comment(props) {
	console.log('COMMENTS\n');
	console.log(props);
	const { idx, feedback, f1, f2, f3, usr_clip_path, gt_clip_path, phoneme } = props;

	return (
		<div className={`comment-panel feedback-${idx}`}>
			<h2>{phoneme}</h2>
			<div className="feedback-container">
				<center>
					<strong>Your audio:</strong>{' '}
					<button
						style={{ background: 'none', border: '0px' , cursor: "pointer"}}
						onClick={() => {
							const sound = new Howl({
								src: [usr_clip_path],
							});
							sound.play();
						}}
					>
						🔊
					</button>
				</center>
				<center>
					<strong>Correct pronunciation:</strong>{' '}
					<button
						style={{ background: 'none', border: '0px' }}
						onClick={() => {
							const sound = new Howl({
								src: [gt_clip_path],
							});
							sound.play();
						}}
					>
						🔊
					</button>
				</center>
				<ul>
					{feedback.map(function(elem) {
						return <li>{elem}</li>;
					})}
				</ul>
				{!(f1.user === 0 && f2.user === 0) && <FormantPlot f1={f1} f2={f2} f3={f3} />}
			</div>
		</div>
	);
}

function CommentList(props) {
	const { comments } = props.feedback;
	if (!comments) return <></>;
	const feedbackComments = comments.filter(elem => elem.feedback.length > 0);
	// const colors = ['#f00', '#0f0', '#00f'];
	return (
		<>
			<div className="comments">
				{feedbackComments.map(elem => (
					<Comment {...elem} />
				))}
			</div>
			{/* <div className="arrows">
				{feedbackComments.map((elem, idx) => (
					<SteppedLineTo from={`english-${elem.idx}`} to={`feedback-${elem.idx}`} delay={1} toAnchor="top center" fromAnchor="bottom center" borderColor={colors[idx % colors.length]} borderWidth={2} zIndex={0} />
				))}
			</div> */}
		</>
	);
}

export default CommentList;
