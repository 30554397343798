import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';


import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
// import "./Dashboard.css";
import { auth, logout } from "./firebase";

import { AudioRecorder } from 'react-audio-voice-recorder';
import logo from './logo.svg';
import SideBar from './SideBar.js';
// import Flashcard from './Flashcard.js';
import Module from './Module.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './App.css';
import { getUsername, getSentenceData } from "./firebase";

// import { getAudio } from "./Guide.js";

// import deckList from './decks.js';
// import sentenceList from './decks.js';


// const decksCompleted = 0;
// const v_percentage = 0;
// const c_percentage = 0;




function DeckInfo(props) {
	const { deck }	= props;
	const capitalizeFirstLetterEachWord = str => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	const deckName = capitalizeFirstLetterEachWord(deck.replace('-', ' '));
	const sentenceList = {
		"front-desk": ['This is a test', 'Enjoy your stay', 'Safe travels'],
		"restaurants": ['Would you like some water', 'Here is the main course', 'May I interest you in dessert'],
		"housekeeping": ['When should I return', 'Here are clean sheets']
	}[deck];
	// const deckName = deck.replace('-', ' ');
	//  make deckName camelcase
	// const deckNameCamel = deckName.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
  const [user, loading, error] = useAuthState(auth);

//   const [decksCompleted, setDecksCompleted] = useState(0);
// 	const [v_percentage, setV_percentage] = useState(0);
// 	const [c_percentage, setC_percentage] = useState(0);

	const [data, setData] = useState("url");
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		if (data !== "url") {
			console.log(data);
			const tmp = new Audio(data); //passing your state (hook)
			tmp.play();
			console.log("PLAYING");
		}
  	}, [data]);
function getAudio(path, sentence, accent) {
	// Using fetch to fetch the api from
	// flask server it will be redirected to proxy
	const formData = new FormData();
	// formData.append('blob', audioRef.current.audioPath);
	formData.append('sentence', sentence);
	formData.append('accent', accent);
	console.log("Form data:");
	console.log(formData);
	
	fetch('/api/sentence', {
		method: 'POST',
		body: formData,
	  }).then((res) => {
		fetch("/audio/".concat(path)).then((res) =>
		res.blob().then((blob) => {
			// convert to url
			// console.log(blob);
			const url = URL.createObjectURL(blob);
			console.log(url);
			setData(url);
		})
	);
	  }
	);
	
}

//   const [name, setName] = useState("");
  const navigate = useNavigate();
//   const fetchUserName = async () => {
//     try {
//       const q = query(collection(db, "users"), where("uid", "==", user?.uid));
//       const doc = await getDocs(q);
//       const data = doc.docs[0].data();
//       setName(data.name);
//     } catch (err) {
//       console.error(err);
//       alert("An error occured while fetching user data");
//     }
//   };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    // fetchUserName();
  }, [user, loading]);


	const [deckIndex, setDeckIndex] = useState(0);
	
    const reset = () => {
    }

	// const [userName, setUserName] = useState("");
	const [sentenceData, setSentenceData] = useState(sentenceList.map((sentence) => ({ [sentence]: [0, 0, 0] })));
	const initSet = async () => {
		// const ret = await getUsername(user.uid);
		// // setUserName(ret["name"]);
		// setDecksCompleted(ret["decksCompleted"]);
		// setV_percentage(ret["v_percentage"]);
		// setC_percentage(ret["c_percentage"]);

		const ret2 = await getSentenceData(user.uid, deck);
		setSentenceData(ret2);
	}

	const [sentenceIndex, setSentenceIndex] = useState(0);

	// console.log(sentenceData[sentenceIndex][sentenceList[sentenceIndex]][0]);

	if (loading) {
		return null;
	}
	if (!user) {
		return navigate("/login");
	}
	initSet();

	

    return (
		<div className="App" style={{minHeight: '100vh'}}>
			<div id="app">
			<nav className="topnav" style={{height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc"}}>
			<a href="/" style={{textDecoration: 'none'}}><p style={{ fontSize: '1.8rem', fontFamily: 'Radikal', position: 'absolute', top: '-7px', left: '4%' }}>
					<span style={{color:'black'}}>speak</span><span style={{ fontFamily: 'Radikal-Bold', color: '#085e99' }}>easy</span>
				</p></a>
				<button onClick={logout} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", position: "absolute", right: "7%", top: "1.8%", cursor: "pointer"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}>Logout</h4></button>

			</nav>
			{/* <div style={{display:"flex", }} */}
			<h4 style={{marginTop: "2%", textAlign: "left", position: "relative", left: "29%", color: "#2e3856", marginBottom: "0px", fontFamily: "Product Sans Medium", fontSize: "1.2em"}}>{deckName}</h4>
		<h4 style={{margin: "0px", textAlign: "left", position: "relative", left: "29%"}}><span style={{fontSize: "0.7em", color: "gray"}}>{sentenceList.length} phrases</span></h4>
		<button onClick={() => {navigate('/'.concat(deck).concat('/learn'))}} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em",  cursor: "pointer", position: "relative", top: "-6vh", left: "14vw"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}>Start learning</h4></button>

		{/* <div className="container"> */}
		<div className="panel record-box" style={{position: "relative", left: "29%", maxWidth: "700px", height: "400px"}}>
					<h3>Try saying:</h3>
					<p className="sentence">{sentenceList[sentenceIndex]}&nbsp;&nbsp;<button style={{fontSize: "1.5rem", background: "none", border: "0px", cursor: "pointer"}} onClick={() => {
						getAudio(sentenceList[sentenceIndex].concat("/".concat("English (US)", ".wav")), sentenceList[sentenceIndex], "English (US)");
					}}>
				🔊
			</button></p>
			<br/>
					<a href={"/".concat(deck).concat("/learn")}><AudioRecorder /></a>
				</div>
				<button onClick={() => {
					setSentenceIndex(sentenceIndex > 0? sentenceIndex - 1: sentenceIndex);
				}} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em",  cursor: "pointer", position: "relative", top: "-2vh", right: "18.3vw"}}><h4 style={{color: "#2e3856", margin: "0px",  fontSize: "1.4em", padding: "8px", paddingBottom: "6px"}}><FaArrowLeft/></h4></button>
						<h4 style={{margin: "0px", textAlign: "left", position: "absolute", left: "47.5%", top: "72%"}}><span style={{fontSize: "0.7em", color: "gray"}}>{sentenceIndex+1} / {sentenceList.length}</span></h4>

				<button onClick={() => {
					setSentenceIndex(sentenceIndex < sentenceList.length - 1? sentenceIndex + 1: sentenceIndex);
				}} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em",  cursor: "pointer", position: "relative", top: "-2vh", left: "15.3vw"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}><FaArrowRight/></h4></button>

			
{sentenceList.map(sentence => (
					<nav style={{height: '18vh', border: "solid 1px #d9dadc", backgroundColor: "white", borderRadius:"10px", marginLeft: "15%", marginRight: "15%", marginTop: "2%", marginBottom: "2%"}}>
					<h4 style={{marginTop: "0px", textAlign: "left", position: "relative", left: "10%", top: "40%", color: "#2e3856", marginBottom: "0px"}}>{sentence}</h4>
					
	<div style={{borderLeft: "2px solid #d9dadc", position: "absolute",
	  height: "11.5vh", left: "36%"}}>
	
	</div>
	
	<div class="statistics2" style={{position: "relative", top: "0%", left: "40%"}}>
							<div class="progress2" style={{width: "250px"}}>
								
								<div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
								<CircularProgressbar value={sentenceData[sentenceList.indexOf(sentence)][sentence][0]} text={`${sentenceData[sentenceList.indexOf(sentence)][sentence][0]}%`} styles={buildStyles({
									// Colors
									pathColor: "#0941b0",
									textColor: "#0941b0",
								  })} />
								</div>
								<div style={{width: "20%"}}>
								<p style={{fontSize: "0.8em"}}> Overall accuracy </p>
								</div>		
	
							</div>
							<div class="progress2" style={{width: "250px"}}>
							<div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
								<CircularProgressbar value={sentenceData[sentenceList.indexOf(sentence)][sentence][1]} text={`${sentenceData[sentenceList.indexOf(sentence)][sentence][1]}%`} styles={buildStyles({
									// Colors
									pathColor: "#0997b0",
									textColor: "#0997b0",
								  })} />	
								</div>
								<div style={{width: "20%"}}>
								<p style={{fontSize: "0.8em"}}> Vowel accuracy </p>		
								</div>					
	
							</div>
							<div class="progress2" style={{width: "250px"}}>
							<div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
							<CircularProgressbar value={sentenceData[sentenceList.indexOf(sentence)][sentence][2]} text={`${sentenceData[sentenceList.indexOf(sentence)][sentence][2]}%`} styles={buildStyles({
									// Colors
									pathColor: "#09b091",
									textColor: "#09b091",
								  })} />
								</div>
								<div style={{width: "20%"}}>
								<p style={{fontSize: "0.8em"}}> Consonant accuracy </p>
								</div>	
								
	
							</div>
	
						</div>
	
	</nav>
				))}

			

			</div>



{/* </div> */}
</div>
	

	);
}
export default DeckInfo;