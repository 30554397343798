import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
// import "./Dashboard.css";
import { auth, logout } from "./firebase";

import { AudioRecorder } from 'react-audio-voice-recorder';
import logo from './logo.svg';
import SideBar from './SideBar.js';
import Guide from './Guide.js';
import './App.css';
import { addFeedback, getCount, getDocContent, getBestAttempt, setBestAttempt, getUsername } from './firebase.js';
import StaticGuide from './StaticGuide.js';


function Dashboard(props) {
const {deck} = props;
const sentenceList = {
	"front-desk": ['This is a test', 'Enjoy your stay', 'Safe travels'],
	"restaurants": ['Would you like some water', 'Here is the main course', 'May I interest you in dessert'],
	"housekeeping": ['When should I return', 'Here are clean sheets']
}[deck];


  const [user, loading, error] = useAuthState(auth);
//   const [name, setName] = useState("");
  const navigate = useNavigate();
//   const fetchUserName = async () => {
//     try {
//       const q = query(collection(db, "users"), where("uid", "==", user?.uid));
//       const doc = await getDocs(q);
//       const data = doc.docs[0].data();
//       setName(data.name);
//     } catch (err) {
//       console.error(err);
//       alert("An error occured while fetching user data");
//     }
//   };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    // fetchUserName();
  }, [user, loading]);

  

//   const index = 0;

  	const [feedback, setFeedback] = useState({ comments: [] });
	  const [pFeedback, setpFeedback] = useState({ comments: [] });
	const [sentenceIndex, setSentenceIndex] = useState(0);
	const [goodResults, setGoodResults] = useState(false);
	const [badResults, setBadResults] = useState(false);
	const [newSentence, setNewSentence] = useState('');

	const [options, setOptions] = useState(['This is a test', 'Enjoy your stay', 'Safe travels']);

	const [numPastAttempts, setNumPastAttempts] = useState();
	const initSet = async () => {
		const count = await getCount(user.uid, sentenceList[sentenceIndex]);
		setNumPastAttempts(count);
	}
	
	const attempt = async (num) => {
		const d = await getDocContent(user.uid, sentenceList[sentenceIndex], num);
		console.log(d);
		setpFeedback(d);
		setAttemptNum(num);
	}

	// attempt(1);
	

	const [accent, setAccent] = useState('English (US)')
	const [pastFeedback, setPastFeedback] = useState(false);

	const [attemptNum, setAttemptNum] = useState(0);

	const [accents, setAccents] = useState(['Afrikaans (South Africa)', 'Arabic', 'Basque (Spain)', 'Bengali (India)', 'Bulgarian (Bulgaria)', 'Catalan (Spain)', 'Chinese (Hong Kong)', 'Czech (Czech Republic)', 'Danish (Denmark)', 'Dutch (Belgium)', 'Dutch (Netherlands)', 'English (Australia)', 'English (India)', 'English (UK)', 'English (US)', 'Filipino (Philippines)', 'Finnish (Finland)', 'French (Canada)', 'French (France)', 'Galician (Spain)', 'German (Germany)', 'Greek (Greece)', 'Gujarati (India)', 'Hebrew (Israel)', 'Hindi (India)', 'Hungarian (Hungary)', 'Icelandic (Iceland)', 'Indonesian (Indonesia)', 'Italian (Italy)', 'Japanese (Japan)', 'Kannada (India)', 'Korean (South Korea)', 'Latvian (Latvia)', 'Lithuanian (Lithuania)', 'Malay (Malaysia)', 'Malayalam (India)', 'Mandarin Chinese', 'Marathi (India)', 'Norwegian (Norway)', 'Polish (Poland)', 'Portuguese (Brazil)', 'Portuguese (Portugal)', 'Punjabi (India)', 'Romanian (Romania)', 'Russian (Russia)', 'Serbian (Cyrillic)', 'Slovak (Slovakia)', 'Spanish (Spain)', 'Spanish (US)', 'Swedish (Sweden)', 'Tamil (India)', 'Telugu (India)', 'Thai (Thailand)', 'Turkish (Turkey)', 'Ukrainian (Ukraine)', 'Vietnamese (Vietnam)']);

  	// const [numPastAttempts, setNumPastAttempts] = useState(getCount(user.uid, deck));

	const addSentence = async event => {
		event.preventDefault();
		setOptions([...options, newSentence]);
		setNewSentence('');
		const formData = new FormData();
        // formData.append('blob', audioRef.current.audioPath);
        formData.append('sentence', newSentence);
        formData.append('accent', accent);
        console.log("Form data:");
        console.log(formData);
        const ret = await fetch('/api/sentence', {
          method: 'POST',
          body: formData,
        });
	};

	const submitAudio = async blob => {
		console.log(blob);
		const formData = new FormData();
		formData.append('blob', blob);
		formData.append('sentence', sentenceList[sentenceIndex]);
		formData.append('accent', accent);
		console.log(formData);
		const result = await fetch('/api/judge', {
			method: 'POST',
			body: formData,
		});
		const data = await result.json();
		console.log(data);
		setFeedback(data);

		addFeedback(user.uid, sentenceList[sentenceIndex], data);
		initSet();

		const overall = data.comments.filter(phoneme => phoneme.feedback.length == 0);
		const percentage = Math.round(overall.length / data.comments.length * 100);

		const VOWELS = "əaiɛeɪoɐiːʌuæaɪɔɑːɚeɪuyɑ̃oʊᵻeaʊoːɔ̃ɜɑ";
		
		/* VOWELS */
		const vowels = overall.filter(phoneme => phoneme.feedback.length == 0 && VOWELS.includes(phoneme.phoneme));
		const all_vowels = data.comments.filter(phoneme => VOWELS.includes(phoneme.phoneme));

		/* CONSONANTS */
		const consonants = overall.filter(phoneme => phoneme.feedback.length == 0 && !VOWELS.includes(phoneme.phoneme));
		const all_consonants = data.comments.filter(phoneme => !VOWELS.includes(phoneme.phoneme));

		const bestAttempt = await getBestAttempt(user.uid, sentenceList[sentenceIndex]);
		const userDetails = await getUsername(user.uid);

		if (bestAttempt.exists()) {
			const d = bestAttempt.data();
			console.log("BEST ATTEMPT");
			console.log(d);
			
			/* OVERALL */
			const overallBest = d.comments.filter(phoneme => phoneme.feedback.length == 0);
			const percentageBest = Math.round(overallBest.length / d.comments.length * 100);
			
			/* VOWELS */
			const vowelsBest = overallBest.filter(phoneme => phoneme.feedback.length == 0 && VOWELS.includes(phoneme.phoneme));
			const all_vowels_best = d.comments.filter(phoneme => VOWELS.includes(phoneme.phoneme));
			// const v_percentage_be = Math.round(vowels.length / all_vowels.length * 100);

			/* CONSONANTS */
			const consonantsBest = overallBest.filter(phoneme => phoneme.feedback.length == 0 && !VOWELS.includes(phoneme.phoneme));
			const all_consonants_best = d.comments.filter(phoneme => !VOWELS.includes(phoneme.phoneme));
			// const c_percentage = Math.round(consonants.length / all_consonants.length * 100);

			const vc = vowels.length - vowelsBest.length;
			const cc = consonants.length - consonantsBest.length; 
			

			if (percentage > percentageBest) {
				setBestAttempt(user.uid, sentenceList[sentenceIndex], data, vc, 0, cc, 0);
			}
		} else {
			setBestAttempt(user.uid, sentenceList[sentenceIndex], data, vowels.length, all_vowels.length, consonants.length, all_consonants.length);
		}

		if (percentage >= 95) {
			setGoodResults(true);
		} else {
			setBadResults(true);
		}
	};

	const skip = () => {
		// alert "Are you sure"
		// alert("Are you sure?")
		if (sentenceIndex === sentenceList.length - 1) {
			// setGoodResults(true);
			alert("You've reached the end!")
			return;
		}
		setSentenceIndex(sentenceIndex + 1);
		setGoodResults(false);
		setBadResults(false);
		setFeedback({ comments: [] });
	}

	const back = () => {
		// alert "Are you sure"
		// alert("Are you sure?")
		if (sentenceIndex === 0) {
			// setGoodResults(true);
			alert("There are no previous phrases!")
			return;
		}
		setSentenceIndex(sentenceIndex - 1);
		setGoodResults(false);
		setBadResults(false);
	}

	const ok = () => {
		setBadResults(false);
	}

	const pastFeedbackFunc = () => {
		setPastFeedback(true);
		// document.getElementById("app").style.filter = "blur(3px)";
	}

	const nopast = () => {
		setPastFeedback(false);
		// document.getElementById("app").style.filter = "blur(0px)";
	}

	const names = {
		"front-desk": "Front Desk",
		"restaurants": "Restaurants",
		"housekeeping": "Housekeeping"

	}

	if (loading) {
		return null;
	}
	initSet();

    return (
		<div className="App" style={{minHeight: '100vh'}}>
			<div id="app">
			<nav className="topnav" style={{height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc"}}>
			<a href="/" style={{textDecoration: 'none'}}><p style={{ fontSize: '1.8rem', fontFamily: 'Radikal', position: 'absolute', top: '-7px', left: '4%' }}>
					<span style={{color:'black'}}>speak</span><span style={{ fontFamily: 'Radikal-Bold', color: '#085e99' }}>easy</span>
				</p></a>
				<h4 style={{marginTop: "0px", textAlign: "center", position: "relative", top: "2.5vh", width: "50%", left: "25%"}}>{names[deck]}</h4>
				<button onClick={logout} className="utility_button logout"><h4 className="utility_button_text">Logout</h4></button>

			</nav>
			<nav style={{height: '9vh', borderBottom: "solid 1px #d9dadc"}}>
				<h4 style={{marginTop: "0px", textAlign: "center", position: "relative", top: "1.6vh", color: "#2e3856", marginBottom: "0px"}}>{sentenceList[sentenceIndex]}</h4>
				<h4 style={{marginTop: "10px"}}><span style={{fontSize: "0.7em", color: "gray"}}>Accent: {accent}</span></h4>

				<button onClick={pastFeedbackFunc} className="utility_button past_feedback"><h4 className="utility_button_text">Past Attempts</h4></button>
				<button onClick={skip} className="utility_button skip"><h4 className="utility_button_text">Next</h4></button>
				<button onClick={back} className="utility_button back"><h4 className="utility_button_text">Previous</h4></button>

			</nav>
			{
!pastFeedback?
<div className="container">
				{/* <SideBar setSentence={setSentence} newSentence={newSentence} setNewSentence={setNewSentence} options={options} addSentence={addSentence} accents={accents} setAccent={setAccent} /> */}
				<Guide sentence={sentenceList[sentenceIndex]} submitAudio={submitAudio} feedback={feedback} accent={accent} />
			</div> : null
			}
			
			{goodResults?
			<div style={{backgroundColor: "white", padding: "3%", position: "fixed", bottom: "0%", minWidth: "100%", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", border: "solid 1px #d9dadc", zIndex: "100"}}>
				<span style={{position: "absolute", left: "20%", top: "35%"}}>Great job! Let's move on.</span>
				<button onClick={skip} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", position: "absolute", right: "7%", top: "28%", cursor: "pointer"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}>Next Phrase</h4></button>

			</div> : null}
			{badResults?
			<div style={{backgroundColor: "white", padding: "3%", position: "fixed", bottom: "0%", minWidth: "100%", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", border: "solid 1px #d9dadc"}}>
				<span style={{position: "absolute", left: "20%", top: "35%"}}>Not quite... give it another try!</span>
				{/* <button style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", position: "absolute", right: "7%", top: "28%"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}>Try Again</h4></button> */}
				<button onClick={ok} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", position: "absolute", right: "10%", top: "28%", cursor: "pointer"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px"}}>OK</h4></button>

			</div> : null}
</div>
			{pastFeedback?
			<>
			<div style={{backgroundColor: "white", position: "fixed", left: "0%", top: "20vh", width: "13%", maxHeight: "70vh", borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "solid 1px #d9dadc", backdropFilter: "blur(4.5px)", overflowY: "auto"}}>
				{/* <nav className="topnav" style={{height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc", borderTopRightRadius: "20px"}}> */}
				<h4 style={{marginTop: "0px", textAlign: "left", position: "relative", top: "2.2vh", left: "9%", fontSize: "1.25rem", color: '#2e3856', marginBottom: "20%"}}>Feedback</h4>
				<button onClick={nopast} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", position: "absolute", right: "4%", top: "2.3%", cursor: "pointer"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "2em", padding: "0px", paddingLeft: "3px", paddingRight: "3px", position: "relative", top:"-1px"}}>×</h4></button>
				{
				Array.from({ length: numPastAttempts}, (_, i) => numPastAttempts-i).map(elem => (
					<button onClick={() => attempt(elem)} style={{border: "none", backgroundColor: "transparent", width: "100%", height: "100%", padding: "0", outline: "none",cursor: "pointer"}}>
						<div className="attempt_button" style={{backgroundColor: attemptNum === elem ? '#e6e7eb' : "#f6f7fb"}}>
							<span className="attempt_text">Attempt {elem}</span>
						</div>
					</button>
				))
				}
			{/* </nav> */}
			</div>
			<div className="container" style={{position: "absolute", top:"18%", left: "6%"}}>
			{/* <SideBar setSentence={setSentence} newSentence={newSentence} setNewSentence={setNewSentence} options={options} addSentence={addSentence} accents={accents} setAccent={setAccent} /> */}
			<StaticGuide feedback={pFeedback} num={attemptNum} />
		</div>
		</>
			: null}
		</div>
	);
}
export default Dashboard;