import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading]);

  return (
    <div className="App">   
      <nav className="topnav" style={{ height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc" }}>
        <a href="/" style={{ textDecoration: 'none' }}>
          <p style={{ fontSize: '1.8rem', fontFamily: 'Radikal', position: 'absolute', top: '-7px', left: '4%' }}>
            <span style={{ color: 'black' }}>speak</span><span style={{ fontFamily: 'Radikal-Bold', color: '#085e99' }}>easy</span>
          </p>
        </a>
        {/* <h4 style={{ marginTop: "0px", textAlign: "center", position: "relative", top: "2.5vh", width: "50%", left: "25%" }}>Your Dashboard</h4> */}
      </nav>   

      <header className="Login-header" >

        <div className="login_box"> 
          <p className="px-20" style={{ color: "black" }}>Ready, set, <span className="medium blue">speak</span></p>

          <div className="login__container">
            <input
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              type="password"
              className="login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button onClick={() => logInWithEmailAndPassword(email, password)} className="login_button">
              <h4 className="utility_button_text">Log in</h4>
            </button>
            {/* <button
              style={{ backgroundColor: '#ee8cc7', border: '0px', borderRadius: '5px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px' }}
            >
              <span style={{ fontFamily: 'Montserrat', color: 'white', fontSize: '1rem' }}>Log in</span>
            </button> */}
          </div>

          <div style={{ maxWidth: "40vw" }}>
            <p style={{ fontSize: '1.1rem', marginTop: '5vh', color: "black" }}>
              Don't have an account? Sign up <a href="/signup" style={{ color: '#085e99' }}>here</a>.
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Login;
