import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
// import "./Dashboard.css";
import { auth, logout } from "./firebase";

import { AudioRecorder } from 'react-audio-voice-recorder';
import logo from './logo.svg';
import SideBar from './SideBar.js';
import Guide from './Guide.js';
import Module from './Module.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './App.css';
import { getUsername } from "./firebase";

// import deckList from './decks.js';
// import sentenceList from './decks.js';
const deckList = ['Front Desk', 'Restaurants', 'Housekeeping']
const description = {
    'Front Desk': 'This module will help you practice the most common phrases used at the front desk.',
    'Restaurants': 'This module will help you practice the most common phrases used at restaurants.',
    'Housekeeping': 'This module will help you practice the most common phrases used in housekeeping.'
};

// const decksCompleted = 0;
// const v_percentage = 0;
// const c_percentage = 0;



function Deck() {
  const [user, loading, error] = useAuthState(auth);

  const [decksCompleted, setDecksCompleted] = useState(0);
	const [v_percentage, setV_percentage] = useState(0);
	const [c_percentage, setC_percentage] = useState(0);

//   const [name, setName] = useState("");
  const navigate = useNavigate();
//   const fetchUserName = async () => {
//     try {
//       const q = query(collection(db, "users"), where("uid", "==", user?.uid));
//       const doc = await getDocs(q);
//       const data = doc.docs[0].data();
//       setName(data.name);
//     } catch (err) {
//       console.error(err);
//       alert("An error occured while fetching user data");
//     }
//   };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    // fetchUserName();
  }, [user, loading]);


	const [deckIndex, setDeckIndex] = useState(0);
	
    const reset = () => {
    }

	const [userName, setUserName] = useState("");
	const initSet = async () => {
		const ret = await getUsername(user.uid);
		setUserName(ret["name"]);
		setDecksCompleted(ret["decksCompleted"]);
		setV_percentage(ret["v_percentage"]);
		setC_percentage(ret["c_percentage"]);
	}

	if (loading) {
		return null;
	}
	if (!user) {
		return navigate("/login");
	}
	initSet();

	

    return (
		<div className="App" style={{minHeight: '100vh', backgroundColor: "white"}}>
			<div id="app">
			<nav className="topnav" style={{height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc"}}>
			<a href="/" style={{textDecoration: 'none'}}><p style={{ fontSize: '1.8rem', fontFamily: 'Radikal', position: 'absolute', top: '-7px', left: '4%' }}>
					<span style={{color:'black'}}>speak</span><span style={{ fontFamily: 'Radikal-Bold', color: '#085e99' }}>easy</span>
				</p></a>
				<h4 style={{marginTop: "0px", textAlign: "center", position: "relative", top: "2.5vh", width: "50%", left: "25%", overflow: "hidden"}}>Your Dashboard</h4>
				<button onClick={logout} className="utility_button logout"><h4 className="utility_button_text">Logout</h4></button>

			</nav>
			<nav style={{height: '20vh', borderBottom: "solid 1px #d9dadc", backgroundColor: "#f6f7fb"}}>
				<h4 style={{marginTop: "0px", textAlign: "left", position: "relative", left: "10%", top: "30%", color: "#2e3856", marginBottom: "0px"}}>{userName}</h4>
				<h4 style={{marginTop: "55px", textAlign: "left", position: "relative", left: "10%"}}><span style={{fontSize: "0.7em", color: "gray"}}>Four Seasons Resort Los Cabos</span></h4>
{/* <button onClick={reset} style={{backgroundColor: "#085e99", border: "none", borderRadius: "1.5em", position: "absolute", left: "10%", top: "20.5%", cursor: "pointer"}}>
<h4 style={{marginTop: "8px", marginBottom: "9px", marginRight: "23px", textAlign: "left", position: "relative", left: "10%"}}><span style={{fontSize: "1.1em", color: "white"}}>Reset progress</span></h4>
</button> */}
{/* <h4 style={{ textAlign: "left", position: "absolute", right: "6%", top: "11.5%"}}><span style={{fontSize: "0.7em", color: "gray"}}>Email: {user.email}</span></h4>
<h4 style={{ textAlign: "left", position: "absolute", right: "6%", top: "14.2%"}}><span style={{fontSize: "0.6em", color: "gray"}}>ID: {user.uid}</span></h4> */}



<div class="statistics2" style={{position: "absolute", top: "13%", left: "33%"}}>
						<div class="progress2" style={{width: "250px"}}>
							
                            <div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
							<CircularProgressbar value={decksCompleted} maxValue={deckList.length} text={`${decksCompleted}`} styles={buildStyles({
								// Colors
								pathColor: "#0941b0",
								textColor: "#0941b0",
  							})} />
                            </div>
                            <div style={{width: "20%"}}>
							<p style={{fontSize: "0.8em"}}> Modules completed </p>
                            </div>		

						</div>
						<div class="progress2" style={{width: "250px"}}>
                        <div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
							<CircularProgressbar value={(Math.round(parseFloat(v_percentage) * 10) / 10).toFixed(1)} text={`${(Math.round(parseFloat(v_percentage) * 10) / 10).toFixed(1)}%`} styles={buildStyles({
								// Colors
								pathColor: "#0997b0",
								textColor: "#0997b0",
  							})} />	
                            </div>
                            <div style={{width: "20%"}}>
                            <p style={{fontSize: "0.8em"}}> Vowel accuracy </p>		
                            </div>					

						</div>
						<div class="progress2" style={{width: "250px"}}>
                        <div style={{width: "31%", marginTop: "8px", marginRight: "15px"}}>
                        <CircularProgressbar value={(Math.round(parseFloat(c_percentage) * 10) / 10).toFixed(1)} text={`${(Math.round(parseFloat(c_percentage) * 10) / 10).toFixed(1)}%`} styles={buildStyles({
								// Colors
								pathColor: "#09b091",
								textColor: "#09b091",
  							})} />
                            </div>
                            <div style={{width: "20%"}}>
							<p style={{fontSize: "0.8em"}}> Consonant accuracy </p>
                            </div>	
							

						</div>

					</div>

</nav>
			{/* </nav>
			<div className="container">
				{/* <Guide sentence={sentenceList[sentenceIndex]} submitAudio={submitAudio} feedback={feedback} accent={accent} /> */}
                <h4 className="medium" style={{marginTop: "2%", textAlign: "left", position: "relative", left: "10%", top: "25%"}}>
                    Choose a module below to get started!
                </h4>
                <p style={{marginTop: "1%", textAlign: "left", position: "relative", left: "10%", top: "25%", color: "#2e3856", marginBottom: "0px", fontSize: "0.8em", marginRight: "30%"}}>
                    Each module contains a series of <span className="medium blue">commonly used phrases</span> in a specific sector of hospitality. You'll have the chance to practice saying each phrase and receive <span className="medium blue">fine-grained feedback</span> on your pronunciation. The goal is that after completing each module, you'll have the <span className="medium blue">clarity</span> and <span className="medium blue">confidence</span> to speak with guests when these phrases pop up in everyday conversations. 
                </p>

                <div style={{display: "flex", flexDirection: "row", position: "relative", left: "9.2%", marginTop: "2%"}}>
				{deckList.map(elem => (
					<Module title={elem} desc={description[elem]}/>
				))}
			    </div>
			</div>
			
			
</div>
			
		// </div>
	);
}
export default Deck;