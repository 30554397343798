import { TbCircleArrowUpRight, TbBed} from 'react-icons/tb';
import {LuConciergeBell} from 'react-icons/lu';
import { IconContext } from 'react-icons';
import {MdDesk, MdOutlineFastfood} from 'react-icons/md';

function Module(props) {
	// console.log('COMMENTS\n');
	// console.log(props);
	const { title, desc } = props;
  const path = "/".concat(title.toLowerCase().replace(" ", "-"));

    const icons = {
        "Front Desk": <LuConciergeBell/>,
        "Restaurants": <MdOutlineFastfood/>,
        "Housekeeping": <TbBed/>
    }

	return (
    <div className="clickable_div">
      <a href={path} style={{ display: "block", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, zIndex: 2 }} />

      <div className="button_background">
        <div style={{ position: "absolute", top: "10%", right: "5%" }}>
          <IconContext.Provider value={{ color: "gray" }}>
            <div>
              <TbCircleArrowUpRight size={30} />
            </div>
          </IconContext.Provider>
        </div>

        <div style={{ position: "relative", top: "11%", left: "5%", textAlign: "left", marginBottom: "12%", width: "20px" }}>
          <IconContext.Provider value={{ color: "gray" }}>
            <div>
              {icons[title]}
            </div>
          </IconContext.Provider>
        </div>

        <h4 className="medium" style={{ marginTop: "3%", marginLeft: "5%", textAlign: "left", marginBottom: "0px" }}>{title}</h4>
        <p style={{ fontSize: "0.8em", marginLeft: "5%", marginTop: "15px", textAlign: "left" }}>{desc}</p>
      </div>
    </div>
	);
}

export default Module;