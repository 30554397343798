import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth, checkLicenseUsed } from './firebase';
import "./Login.css";
import { addUserDetails } from './firebase';

 
const Signup = () => {
    const navigate = useNavigate();
 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [license, setLicense] = useState('');
    const [name, setName] = useState('');
 
    const onSubmit = async (e) => {
      e.preventDefault()

      const licenses = [
        "nt45iyqpa6",
        "uw4mzjfk67",
        "51qdj1y1jx",
        "6umsnvll0w",
        "n5hxj4ffvq",
        "525jznycot",
        "4cyp9g02sd",
        "0h95xhsgfa",
        "sd82wledmg",
        "1vq8wdysbi",
        "yjmfdkkyvn",
        "3m6carxg77",
        "kqj48kbezt",
        "wz37fcfvzk",
        "p0co73lkew",
        "e74aewxcrf",
        "68pjvtotv9",
        "ieqdhm9yly",
        "81ii04n0er",
        "r98jopiuiv",
        "ry53b1pzhc",
        "hwrd9tlqzn",
        "8m24cogvb7",
        "r1t7sj10gf",
        "uwjtfjohpa"
      ];
      // TODO: take in name
      // TODO: only use license once
      

      if (!licenses.includes(license)) {
        alert("Please enter a valid license.")
        return;
      }

      const licenseUsed = await checkLicenseUsed(license);
      console.log(licenseUsed);
      if (licenseUsed) {
        alert("This license has already been used. Try another.")
        return;
      }


     
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(errorMessage);
      });
      if (userCredential) {
        const user = userCredential.user;
        await addUserDetails(user.uid, name, license);
        navigate("/login");
      }
      // Signed in
      
        // .then((userCredential) => {
        //     // Signed in
        //     const user = userCredential.user;
        //     console.log(user);
        //     // await addUserDetails(user.uid, name);
        //     navigate("/login")
        //     // ...
        // })
        // .catch((error) => {
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        //     console.log(errorCode, errorMessage);
        //     // ..
        // });
 
   
    }
 
  return (
    <div className="App">   
    <nav className="topnav" style={{height: '9vh', backgroundColor: "white", borderBottom: "solid 1px #d9dadc"}}>
			<a href="/" style={{textDecoration: 'none'}}><p style={{ fontSize: '1.8rem', fontFamily: 'Radikal', position: 'absolute', top: '-7px', left: '4%' }}>
					<span style={{color:'black'}}>speak</span><span style={{ fontFamily: 'Radikal-Bold', color: '#085e99' }}>easy</span>
				</p></a>
				{/* <h4 style={{marginTop: "0px", textAlign: "center", position: "relative", top: "2.5vh", width: "50%", left: "25%"}}>Your Dashboard</h4> */}
			</nav>   
        <header className="Login-header">
          
         {/* <a href="https://fixyouraccent.com" style={{textDecoration:'none'}}><p style={{left: "10.5%", fontFamily: "Radikal", position: 'absolute', top: '10px', fontSize: '1.763rem'}}><span style={{color:'black'}}>speak</span><span style={{fontFamily: "Radikal-Bold", color:"#ee8cc7"}}>easy</span></p></a> */}
        
         <div className="login_box"> 
          <p className="px-20" style={{color:"black"}}>Create an <span className="medium blue">account</span>
          </p>
        
          <div className="login__container">
          <form>                                     

            <div style={{width: "400px"}}>
                            {/* <label htmlFor="email-address">
                                Email address
                            </label> */}
                            <input
                                type="name"
                                label="Full name"
                                className="login__textBox"
                                value={name}
                                onChange={(e) => setName(e.target.value)}  
                                required                                    
                                placeholder="Full name"                                
                            />
                        </div>

                        <div style={{width: "400px"}}>
                            {/* <label htmlFor="email-address">
                                Email address
                            </label> */}
                            <input
                                type="email"
                                label="Email address"
                                className="login__textBox"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}  
                                required                                    
                                placeholder="Email address"                                
                            />
                        </div>

                        <div>
                            {/* <label htmlFor="password">
                                Password
                            </label> */}
                            <input
                                type="password"
                                label="Create password"
                                className="login__textBox"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                                required                                 
                                placeholder="Password"  
                                // size={20}
                                // width={"50px"}            
                            />
                        </div>           

                        <div style={{width: "400px"}}>
                            {/* <label htmlFor="password">
                                Password
                            </label> */}
                            <input
                                type="license"
                                label="Enter Speakeasy-provided license"
                                className="login__textBox"

                                value={license}
                                onChange={(e) => setLicense(e.target.value)} 
                                required                                 
                                placeholder="License key"  

                                // size={20}
                                // width={"50px"}            
                            />
                        </div>                                       
                        
                        {/* <button
                            type="submit" 
                            onClick={onSubmit}                        
                        >  
                            Sign up                                
                        </button> */}
                             				<button type="submit" onClick={onSubmit} style={{backgroundColor: "#f6f7fb", border: "solid 1.5px #d9dadc", borderRadius: "0.5em", cursor: "pointer", marginTop: "20px"}}><h4 style={{color: "#2e3856", margin: "0px", fontSize: "1.4em", padding: "8px",}}>Sign up</h4></button>
                                        
                    </form>
        


        {/* <button
        style={{backgroundColor: '#ee8cc7', border: '0px', borderRadius: '5px', paddingTop: '10px', paddingBottom: '10px', paddingLeft:'10px', paddingRight:'10px', marginTop: '10px'}}
          
        >
          <span style={{ fontFamily: 'Montserrat', color: 'white', fontSize: '1rem'}}>Log in</span>
        </button> */}
      </div>

    <div style={{maxWidth: "40vw"}}>
      <p style={{fontSize: '1.1rem', marginTop: '5vh', color:"black"}}>
        Already have an account? Log in <a href="/login" style={{color: '#085e99'}}>here</a>.
</p></div>
    </div>
        </header>
      </div>
  );
//     <main >        
//         <section>
//             <div>
//                 <div>                  
//                     <h1> FocusApp </h1>                                                                            
//                     <form>                                                                                            
//                         <div>
//                             <label htmlFor="email-address">
//                                 Email address
//                             </label>
//                             <input
//                                 type="email"
//                                 label="Email address"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}  
//                                 required                                    
//                                 placeholder="Email address"                                
//                             />
//                         </div>

//                         <div>
//                             <label htmlFor="password">
//                                 Password
//                             </label>
//                             <input
//                                 type="password"
//                                 label="Create password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)} 
//                                 required                                 
//                                 placeholder="Password"              
//                             />
//                         </div>                                             
                        
//                         <button
//                             type="submit" 
//                             onClick={onSubmit}                        
//                         >  
//                             Sign up                                
//                         </button>
                                                                     
//                     </form>
                   
//                     <p>
//                         Already have an account?{' '}
//                         <NavLink to="/login" >
//                             Sign in
//                         </NavLink>
//                     </p>                   
//                 </div>
//             </div>
//         </section>
//     </main>
//   )
}
 
export default Signup