import { useRef, useMemo, createRef, useEffect, useState, useLayoutEffect } from 'react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import {Howl, Howler} from "howler";
// import soundObj from './en-US-Neural2-A.wav';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import CommentList from './Comment.js';

function EnglishSubunit(props) {
	const isErroneous = props.feedback.length > 0;
	return (
		<div key={props.idx}>
			<div className={`english-subunit  error-${isErroneous} english-${props.idx} word-end-${props.word_end}`} key={props.idx}>
				<span className="en">{props.en}</span>
				<span className="phoneme">{props.phoneme}</span>
			</div>
		</div>
	);
}


function SuccessBar(props) {
	console.log('PROPS FEEDBACK');
	if (props.feedback.comments.length > 0) {
		const VOWELS = "əaiɛeɪoɐiːʌuæaɪɔɑːɚeɪuyɑ̃oʊᵻeaʊoːɔ̃ɜɑ";

		/* OVERALL */
		const overall = props.feedback.comments.filter(phoneme => phoneme.feedback.length == 0);
		const percentage = Math.round(overall.length / props.feedback.comments.length * 100);
		
		/* VOWELS */
		const vowels = overall.filter(phoneme => phoneme.feedback.length == 0 && VOWELS.includes(phoneme.phoneme));
		const all_vowels = props.feedback.comments.filter(phoneme => VOWELS.includes(phoneme.phoneme));
		const v_percentage = Math.round(vowels.length / all_vowels.length * 100);

		/* CONSONANTS */
		const consonants = overall.filter(phoneme => phoneme.feedback.length == 0 && !VOWELS.includes(phoneme.phoneme));
		const all_consonants = props.feedback.comments.filter(phoneme => !VOWELS.includes(phoneme.phoneme));
		const c_percentage = Math.round(consonants.length / all_consonants.length * 100);

		return (
			<>
				<h1>Your Results</h1>

					<div class="statistics">
						<div class="progress">
							<CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
								// Colors
								pathColor: "#0941b0",
								textColor: "#0941b0",
  							})} />
							<p> Overall accuracy </p>

						</div>
						<div class="progress">
							<CircularProgressbar value={v_percentage} text={`${v_percentage}%`} styles={buildStyles({
								// Colors
								pathColor: "#0997b0",
								textColor: "#0997b0",
  							})} />					
							<p> Vowel accuracy </p>

						</div>
						<div class="progress">
							<CircularProgressbar value={c_percentage} text={`${c_percentage}%`} styles={buildStyles({
								// Colors
								pathColor: "#09b091",
								textColor: "#09b091",
  							})} />
							<p> Consonant accuracy </p>

						</div>

					</div>



					<div style={{ }}>

					</div>
					<div style={{ width: 150, height: 150 }}>
					</div>

			
			</>


		);
	}
}

function StaticGuide(props) {
	// const refs = useRef({});
	// function addRef(key: string) {
	// 	if (refs.current[key]) {
	// 		return refs.current[key];
	// 	} else {
	// 		const newRef = createRef();
	// 		refs.current[key] = newRef;
	// 		return newRef;
	// 	}
	// }

	const [data, setData] = useState("url");
	// useEffect(() => {
	// 	
	// }, [data]);
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		if (data !== "url") {
			console.log(data);
			const tmp = new Audio(data); //passing your state (hook)
			tmp.play();
			console.log("PLAYING");
		}
  	}, [data]);
	// useEffect(() => {
	// 	// Update the document title using the browser API
	// 	console.log(data);
	// 	const tmp = new Audio(data); //passing your state (hook)
	// 	tmp.play()
	// 	console.log("PLAYING");
	//   }, [data]);

	
 
    // Using useEffect for single rendering
    function getAudio(path, sentence, accent) {
		// Using fetch to fetch the api from
		// flask server it will be redirected to proxy
		const formData = new FormData();
        // formData.append('blob', audioRef.current.audioPath);
        formData.append('sentence', sentence);
        formData.append('accent', accent);
        console.log("Form data:");
        console.log(formData);
        
		fetch('/api/sentence', {
			method: 'POST',
			body: formData,
		  }).then((res) => {
			fetch("/audio/".concat(path)).then((res) =>
			res.blob().then((blob) => {
				// convert to url
				// console.log(blob);
				const url = URL.createObjectURL(blob);
				console.log(url);
				setData(url);
			})
		);
		  }
		);
		
	}


	return (
		<>
			<div className="guide">
				{props.num != 0?
				<h3>Attempt {props.num}</h3> : null
				}
			
				{/* <div className="panel record-box">
					// 
					<p className="sentence">{props.sentence}&nbsp;&nbsp;<button style={{fontSize: "1.5rem", background: "none", border: "0px", cursor: "pointer"}} onClick={() => {
						// const result = await fetch('/api/judge', {
						// 	method: 'POST',
						// 	body: formData,
						// });
						// console.log(props.accent);
						getAudio(props.sentence.concat("/".concat(props.accent, ".wav")), props.sentence, props.accent);
						
					}}>
				🔊
			</button></p>
			<br/>
					<AudioRecorder onRecordingComplete={props.submitAudio} />
				</div> */}
				<div className="panel feedback" style={{marginTop: "2%"}}>
					<div className="english-breakdown">{props.feedback.comments && props.feedback.comments.map(elem => <EnglishSubunit {...elem} ></EnglishSubunit>)}</div>
					{/* <SuccessBar props={props} /> */}
					{/* <div className="comments">
						{props.feedback.comments &&
							props.feedback.comments
								.filter(elem => elem.feedback.length > 0)
								.map(elem => {
									return (
										<div className={`comment-panel feedback-${elem.idx}`}>
											<p>{elem.feedback}</p>
										</div>
									);
								})}
					</div> */}
					<CommentList feedback={props.feedback} />
					<br/>
					<SuccessBar feedback={props.feedback} />

				</div>
			</div>
			{/* <div>
				{props.feedback.comments &&
					props.feedback.comments
						.filter(elem => elem.feedback.length > 0)
						.map(elem => {
							return (
								<div key={elem.idx}>
									<SteppedLineTo from={`english-${elem.idx}`} to={`feedback-${elem.idx}`} delay={1} toAnchor="top center" fromAnchor="bottom center" />
								</div>
							);
						})}
			</div> */}
		</>
	);
}

export default StaticGuide;
