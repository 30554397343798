import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import Deck from "./Deck";
import DeckInfo from "./DeckInfo";
import './App.css';
import './index.css';

const deckList = ['front-desk', 'restaurants', 'housekeeping'];

function App() {
	return (
		<div className="app">
			{/* <Head>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
			</Head> */}
			<Router>
				<Routes>
				{deckList.map(elem => (
					<Route exact path={"/".concat(elem)} element={<DeckInfo deck={elem}/>} />
				))}
				{deckList.map(elem => (
					<Route exact path={"/".concat(elem).concat("/learn")} element={<Dashboard deck={elem}/>} />
				))}
				<Route path="/signup" element={<Signup/>}/>
				<Route exact path="/learn" element={<Dashboard/>} />
				<Route exact path="/" element={<Deck />} />
				<Route exact path="/login" element={<Login />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;